import { useAuth } from "../components/authContextProvider";
import { useState, useEffect } from "react";

export const useUserHelper = () => {
  const { decodedToken } = useAuth();

  const [isBayerUser, setIsBayerUser] = useState(
    decodedToken && decodedToken["cognito:preferred_role"].includes("/pmxUser/")
  );
  const [isUnactivatedUser, setIsUnactivatedUser] = useState(
    decodedToken &&
      decodedToken["cognito:preferred_role"].includes("/unactivatedUser/")
  );
  const [isTestUser, setIsTestUser] = useState(
    decodedToken &&
      (["test-user", "test-user-ext"].includes(
        decodedToken["cognito:username"]
      ) ||
        decodedToken.email?.endsWith("test-user@bayer.com"))
  );
  const [isOtherUser, setIsOtherUser] = useState(
    decodedToken &&
      decodedToken["cognito:preferred_role"].includes("/otherUser/")
  );

  useEffect(() => {
    setIsBayerUser(
      decodedToken &&
        decodedToken["cognito:preferred_role"].includes("/pmxUser/")
    );
    setIsUnactivatedUser(
      decodedToken &&
        decodedToken["cognito:preferred_role"].includes("/unactivatedUser/")
    );
    setIsOtherUser(
      decodedToken &&
        decodedToken["cognito:preferred_role"].includes("/otherUser/")
    );
    setIsTestUser(
      decodedToken &&
        (["test-user", "test-user-ext"].includes(
          decodedToken["cognito:username"]
        ) ||
          decodedToken.email?.endsWith("test-user@bayer.com"))
    );
  }, [decodedToken]);

  return { isBayerUser, isUnactivatedUser, isTestUser, isOtherUser };
};
