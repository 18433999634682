import {
  GridColDef,
  GridSortDirection,
  GridSortModel,
  GridValueGetterParams,
  DataGridPro,
  GridFilterModel
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetAuditTrailEventsQuery } from "../../backend/hooks/auditTrail/queryGetAuditTrailEvents";
import Error from "../abstractComponents/error";
import {
  Button,
  Col,
  Container,
  Row,
  Spinner,
  Modal,
  Table
} from "react-bootstrap";
import {
  customFilters,
  customDateFilters,
  customNumberFilters
} from "../../helpers/tableViewHelper";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { useDownloadAuditEvents } from "../../backend/hooks/auditTrail/mutationDownloadAuditEvents";
import Success from "../abstractComponents/success";
import Stack from "@mui/material/Stack";
import { useGetAuditTrailEventByIdQuery } from "../../backend/hooks/auditTrail/queryGetAuditTrailEventById";

export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
    filterOperators: customNumberFilters
  },
  {
    field: "userId",
    headerName: "User",
    width: 200,
    filterOperators: customFilters
  },
  {
    field: "created",
    headerName: "Created",
    type: "date",
    flex: 1,
    filterOperators: customDateFilters
  },
  {
    field: "repository",
    headerName: "Repository",
    flex: 1,
    filterOperators: customFilters,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.repository &&
      (params.row.repository.name
        ? params.row.repository.name
        : params.row.repository.id + " (Deleted)")
  },
  {
    field: "actionType",
    headerName: "Action Type",
    width: 150,
    filterOperators: customFilters
  },
  {
    field: "data",
    headerName: "Data",
    flex: 8,
    valueGetter: (params: GridValueGetterParams) => {
      let rowData = params.row.data.replace("Archive", "Compress");
      let jsonRowData = JSON.parse(rowData);
      if (
        jsonRowData.old_values &&
        jsonRowData.old_values.quality_check_status === null
      ) {
        jsonRowData.old_values.quality_check_status = "None";
      }

      if (
        jsonRowData.new_values &&
        jsonRowData.new_values.quality_check_status === null
      ) {
        jsonRowData.new_values.quality_check_status = "None";
      }

      return JSON.stringify(jsonRowData);
    },
    sortable: false,
    filterable: false
  }
];

export function AuditTrail() {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "created",
      sort: "desc" as GridSortDirection
    }
  ]);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [selectedAuditId, setSelectedAuditId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { auditQuery, auditTrailEvent, isLoading, error } =
    useGetAuditTrailEventsQuery();

  const [rowCountState, setRowCountState] = useState(
    auditTrailEvent?.getAuditTrailEvents?.totalRowCount || 0
  );

  const {
    downloadAuditEventsMutation,
    downloadAuditEventsMutationData,
    isLoadingDownloadMutation,
    errorDownloadAuditEventsMutation
  } = useDownloadAuditEvents();

  const handleDownloadAuditTrail = () => {
    downloadAuditEventsMutation();
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
    setSelectedAuditId(undefined);
  };

  useEffect(() => {
    if (selectedAuditId) {
      setIsModalVisible(true);
      auditQueryId({ variables: { auditId: selectedAuditId } });
    }
  }, [selectedAuditId]);

  const { auditQueryId, auditTrailEventId, isLoadingId, errorId } =
    useGetAuditTrailEventByIdQuery();

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      auditTrailEvent?.getAuditTrailEvents?.totalRowCount !== undefined
        ? auditTrailEvent?.getAuditTrailEvents?.totalRowCount
        : prevRowCountState
    );
  }, [auditTrailEvent?.getAuditTrailEvents?.totalRowCount]);

  useEffect(() => {
    document.title = "PHIL App - Audit Trail";
  }, []);

  useEffect(() => {
    auditQuery({ variables: { page, pageSize, sort: sortModel } });
  }, [sortModel, page, pageSize]);

  useEffect(() => {
    const isValidFilter = (item: any) => {
      if (Reflect.has(item, "value")) {
        if (
          item.operatorValue &&
          ["=", ">=", "<=", "!="].includes(item.operatorValue)
        ) {
          return item.value !== undefined;
        }
        return true;
      }
      return false;
    };

    if (filterModel) {
      if (
        filterModel.items.length === 0 ||
        filterModel.items.every(isValidFilter)
      ) {
        const filter =
          filterModel.items.length > 0
            ? {
                items: filterModel.items.map((item) => ({
                  columnField: item.columnField,
                  operatorValue: item.operatorValue,
                  value: item.value
                })),
                linkOperator: filterModel.linkOperator
              }
            : undefined;

        auditQuery({
          variables: {
            page,
            pageSize,
            sort: sortModel,
            filter
          }
        });
      }
    }
  }, [filterModel, page, pageSize, sortModel, auditQuery]);

  return (
    <div className="app_content">
      <h2>Audit Trail Events</h2>
      <Row>
        <Col></Col>
        <Col>
          <Button
            id="download_audit_trail"
            className="float-end button-secondary"
            onClick={handleDownloadAuditTrail}
          >
            <DownloadingIcon /> Full Export to email
          </Button>
        </Col>
      </Row>
      <br />
      {isLoadingDownloadMutation && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      )}
      {!error && (
        <Container fluid>
          <div className="list_repositories_container">
            <DataGridPro
              rows={auditTrailEvent?.getAuditTrailEvents?.auditTrail || []}
              rowCount={rowCountState}
              columns={columns}
              className="styled_data_grid"
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => setPageSize(pageSize)}
              pagination
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              getRowHeight={() => "auto"}
              sortingMode="server"
              filterMode="server"
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) =>
                setFilterModel(newFilterModel)
              }
              autoHeight
              paginationMode={"server"}
              rowsPerPageOptions={[25, 50, 100, 500, 1000]}
              loading={isLoading}
              onRowClick={(row) => {
                setSelectedAuditId(row.id);
              }}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No Audit Actions
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Filter returns no result
                  </Stack>
                )
              }}
            />
            {downloadAuditEventsMutationData?.downloadAuditTrailEvents
              ?.actionStatus === "Pending" && (
              <Success message="Audit trails export has started. It can take up to 15 Minutes to email the link once completed." />
            )}
          </div>
        </Container>
      )}

      <Modal
        dialogClassName="modal-75w"
        className="file-select-modal"
        show={isModalVisible}
        onHide={onCloseModal}
      >
        <Modal.Header closeButton className="qc_badge">
          <Modal.Title id="custom-modal-styling-title">Audit Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoadingId && (
            <div className="center_div">
              <Spinner animation="border" className="spinner_color" />
              <p>Loading...</p>
            </div>
          )}
          {auditTrailEventId && auditTrailEventId.getAuditTrailById && (
            <Table bordered hover>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{auditTrailEventId.getAuditTrailById.id}</td>
                </tr>
                <tr>
                  <td>Audit Type</td>
                  <td>{auditTrailEventId.getAuditTrailById.actionType}</td>
                </tr>
                <tr>
                  <td>Created</td>
                  <td>{auditTrailEventId.getAuditTrailById.created}</td>
                </tr>
                <tr>
                  <td>User</td>
                  <td>{auditTrailEventId.getAuditTrailById.userId}</td>
                </tr>
                {auditTrailEventId.getAuditTrailById?.repository && (
                  <tr>
                    <td>Repository</td>
                    <td>
                      {auditTrailEventId.getAuditTrailById.repository?.name
                        ? auditTrailEventId.getAuditTrailById.repository.name
                        : auditTrailEventId.getAuditTrailById.repository.id +
                          " (Deleted)"}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Data</td>
                  <td>
                    <pre>
                      {JSON.stringify(
                        JSON.parse(auditTrailEventId.getAuditTrailById.data),
                        null,
                        2
                      )}
                    </pre>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer className="footer">
          <Button variant="secondary" onClick={onCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {(error || errorId) && <Error error={error || errorId} />}
      {errorDownloadAuditEventsMutation && (
        <Error error={errorDownloadAuditEventsMutation} />
      )}
    </div>
  );
}
